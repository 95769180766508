<template lang='pug'>
div#app
  router-view#content
</template>

<script>
export default {
  name: "app"
}
</script>

<style lang='scss'>
html, body {
  height: 100vh;
  margin: 0;
  padding: 0;

  font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;

  overflow-x: hidden;
  overflow-y: scroll;
}

#app {
  height: 100%;

  text-align: center;
  color: $black;
}

#sitenav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  a {
    display: inline-block;
  }

  a + a {
    margin-left: 1rem;
  }
}

#content {
  max-width: 48rem;
  height: 100%;
  margin: 0 auto;
  padding: 1rem;

  box-sizing: border-box;
}
</style>
