import Vue from 'vue'
import Router from 'vue-router'

const Login = () => import('./pages/Login.vue')
const ForgotPassword = () => import('./pages/ForgotPassword.vue')
const ResetPassword = () => import('./pages/ResetPassword.vue')
const CreateAccount = () => import('./pages/CreateAccount.vue')
const EditAccount = () => import('./pages/EditAccount.vue')
const ConfirmAccount = () => import('./pages/ConfirmAccount.vue')
const InvalidCode = () => import('./pages/InvalidCode.vue')
const Logout = () => import('./pages/Logout.vue')

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      alias: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/create',
      name: 'create',
      component: CreateAccount
    },
    {
      path: '/edit',
      name: 'edit',
      component: EditAccount
    },
    {
      path: '/confirm',
      name: 'confirm',
      component: ConfirmAccount
    },
    {
      path: '/forgot',
      name: 'forgot',
      component: ForgotPassword
    },
    {
      path: '/reset',
      name: 'reset',
      component: ResetPassword
    },
    {
      path: '/invalid',
      name: 'invalid',
      component: InvalidCode
    },
    {
      path: '/logout',
      name: 'logout',
      component: Logout
    }
  ]
})

export default router