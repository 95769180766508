import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuex from 'vuex'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/en'

Vue.use(ElementUI, { locale })
Vue.use(Vuex)

Vue.config.productionTip = false

window.Intercom('boot', {
  app_id: 'r3gz2yf5',
  site: 'janus'
})

const store = new Vuex.Store({
  state: {
    baseURL: '/api/'
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")

export { store }
